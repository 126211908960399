<template>
  <div class="w-100 p-l-30 p-r-30">
    <div v-if="deleteSuccess" class="alert alert-success" role="alert">
      大会が削除されました。
    </div>
    <div v-if="deleteFailed" class="alert alert-danger" role="alert">
      トーナメントは削除されていません。
    </div>
    <!-- 12-7 -->

    <div>
      <div class="contents-header">
        <div class="separator">
          <p class="breadcrumb-menu">
            <router-link :to="{ name: 'Tournaments', query: this.createSearchQuery() }" class="ashen-link">大会管理
            </router-link>
            <i class="fas fa-angle-right form-control-color"></i> 大会情報
          </p>
        </div>
        <div class="card border-0 text-first mt-3">
          <div class="row d-flex justify-content-between">
            <div v-if="prevTournamentId" class="col-6">
              <router-link :to="linkPrevTournament" class="ashen-link mx-1" replace>
                <i class="fas fa-angle-left"></i>
                {{ prevTournamentName }}
              </router-link>
            </div>
            <div v-else class="col-6"></div>
            <div v-if="nextTournamentId" class="col-6 text-end">
              <router-link :to="linkNextTournament" class="ashen-link mx-1" replace>
                {{ nextTournamentName }}<i class="fas fa-angle-right mx-1"></i>
              </router-link>
            </div>
            <div v-else class="col-6"></div>
          </div>
        </div>
      </div>


      <div>
        <div class="card-body mx-auto">
          <div class="row mt-4">
            <div class="col">
              <label for="exampleInputPassword1">大会名</label>
              <input v-model="jtaTournament.tournamentName" class="form-control"
                     type="text">
              <div v-show="errors['tournamentName']" class="form-error">{{ errors['tournamentName'] }}</div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <label for="startDate">大会開始日</label>
              <div class="input-group">
                <Calender v-bind:currentDate="jtaTournament.startAt"
                          @update="updateStartAt"
                ></Calender>
              </div>
              <div v-show="errors['startAt']" class="form-error">{{ errors['startAt'] }}</div>
            </div>
            <div class="col">
              <label for="endDate">大会終了日</label>
              <div class="input-group">
                <Calender v-bind:currentDate="jtaTournament.endAt"
                          @update="updateEndAt"
                ></Calender>
              </div>
              <div v-show="errors['endAt']" class="form-error">{{ errors['endAt'] }}</div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <label>開催都道府県</label>
              <select v-model="jtaTournament.hostPrefectureId"
                      class="form-control filter-select-long form-select mt-1">
                <option
                    v-for="(prefecture, prefectureId) in prefectures"
                    :key="prefectureId"
                    :value="prefecture.prefectureId"
                >
                  {{ prefecture.prefectureName }}
                </option>
              </select>
              <div v-show="errors['hostPrefectureId']" class="form-error">{{ errors['hostPrefectureId'] }}</div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <label for="exampleInputPassword1">主催団体</label>
              <input v-model="jtaTournament.hostOrganization" class="form-control" type="text">
              <div v-show="errors['hostOrganization']" class="form-error">{{ errors['hostOrganization'] }}</div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <label for="exampleInputPassword1">お問い合わせ電話番号</label>
              <input v-model="jtaTournament.inquiryPhoneNo" class="form-control"
                     type="email">
              <div v-show="errors['inquiryPhoneNo']" class="form-error">{{ errors['inquiryPhoneNo'] }}</div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <label for="exampleInputPassword1">お問い合わせメール</label>
              <input v-model="jtaTournament.inquiryEmail" class="form-control"
                     type="email">
              <div v-show="errors['inquiryEmail']" class="form-error">{{ errors['inquiryEmail'] }}</div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <label for="exampleInputPassword1">大会管理者<span
                  class="text-888888">（複数名の場合はセミコロン「;」で区切って入力してください）</span></label>
              <input v-model="jtaTournament.tournamentAdminEmail" class="form-control"
                     type="email">
              <div v-show="errors['tournamentAdminEmail']" class="form-error">{{ errors['tournamentAdminEmail'] }}</div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <label for="exampleInputPassword1">請求書先</label>
              <input v-model="jtaTournament.billingDestination" class="form-control"
                     type="text">
              <div v-show="errors['billingDestination']" class="form-error">{{ errors['billingDestination'] }}</div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <label>利用者登録フォーム</label>
              <div class="form-control color-disable p-t-10"><a :href="jtaTournament.registerURL" class="color-pink"
                                                                target="_blank">{{ jtaTournament.registerURL }}</a>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <label>ログインフォーム</label>
              <div class="form-control color-disable p-t-10"><a :href="jtaTournament.loginURL" class="color-pink"
                                                                target="_blank">{{ jtaTournament.loginURL }}</a>
              </div>
            </div>
          </div>
          <div class="form-check ml-2 mt-4 mx-auto">
            <input id="pastAnswersRequired" v-model="jtaTournament.pastAnswersRequired" class="form-check-input m-t-5"
                   type="checkbox" value="">
            <label class="form-check-label" for="pastAnswersRequired">過去14日間における回答をさせる
            </label>
          </div>
          <div class="form-check ml-2 mt-1 mx-auto">
            <input id="hasHeatStroke" v-model="jtaTournament.hasHeatStroke" class="form-check-input m-t-5"
                   type="checkbox" value="">
            <label class="form-check-label" for="hasHeatStroke">熱中症の項目を表示させる
            </label>
          </div>
          <div class="row mt-4 d-flex justify-content-between mt-4">
            <div class="col-4">
              <div class="btn-width">
                <Modal :back-to-root="backToTournament"
                       :data="jtaTournament"
                       :reload-data="getTournament"
                       :tournament-delete="deleteTournament"
                       body=" を削除してもよろしいですか？" button-title="削除" cancel-title="キャンセル"
                       ok-title="削除" title="削除"></Modal>
              </div>
            </div>
            <div class="col-8 text-end">
              <button class="btn btn-light bg-white ashen-link text-decoration-none light border-0 btn-width"
                      @click="backToTournament">キャンセル
              </button>
              <button class="btn col btn-primary btn-outline-light font-weight-bold btn-width float-end"
                      @click="updateTournament">変更
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {apiConfig, app} from "@/constants"
import Datatable from "../components/datatable/Datatable"
import DatePicker from "../components/datepicker/DatePicker"
import {mapGetters} from "vuex"
import Modal from "../components/modal/Modal"
import Common from "jsadminclient/common"
import Calender from "../components/layouts/Calendar";

export default {
  name: "TournamentDetail",
  components: {Datatable, DatePicker, Modal, Calender},
  props: {
    tournamentId: {type: Number, default: null},
    defaultResponse: {type: Object, default: null},
  },
  data() {
    return {
      app: app,
      apiConfig: apiConfig,
      deleteSuccess: false,
      deleteFailed: false,
      reloadKey: 1,
      loading: true,
      tournament: {},
      nextTournamentId: '',
      nextTournamentName: '',
      prevTournamentId: '',
      prevTournamentName: '',
      jtaTournament: {},
      // tournament: {},
      prefectures: {},
      errors: []
    };
  },
  created() {
    this.apiConfig.headers["X-API-Token"] = this.config.token
  },
  mounted() {
    this.initialPageLoadHandler()
  },
  watch: {
    $route(to, from) {
      // Called from back or next
      this.getTournament()
    }
  },
  computed: {
    ...mapGetters(["config"]),
    startAtForDatePicker() {
      return Common.dateFormatConvertForDatePicker(this.jtaTournament.startAt)
    },
    endAtForDatePicker() {
      return Common.dateFormatConvertForDatePicker(this.jtaTournament.endAt)
    },
    linkPrevTournament() {
      return {
        name: "TournamentDetail",
        params: {
          tournamentId: this.prevTournamentId
        },
        query: this.createSearchQuery()
      }
    },
    linkNextTournament() {
      return {
        name: "TournamentDetail",
        params: {
          tournamentId: this.nextTournamentId
        },
        query: this.createSearchQuery()
      }
    },
  },
  methods: {
    initialPageLoadHandler() {
      if (this.defaultResponse) {
        this.response = this.defaultResponse
      }
      this.getPrefecture()
      this.getTournament()
    },
    backToTournament() {
      this.$router.push({name: 'Tournaments', query: this.createSearchQuery()})
    },
    getApiConfig() {
      return new AdminApi.ApiConfig(
          this.apiConfig.basePath,
          this.apiConfig.headers
      );
    },
    getDateFormat(dateObject, addMonths = 0) {
      return moment(dateObject).add(addMonths, "months").format("YYYY/MM/DD")
    },
    changeStartDate(e) {
      this.jtaTournament.startAt = this.getDateFormat(e.target.value)
    },
    changeEndDate(e) {
      this.jtaTournament.endAt = this.getDateFormat(e.target.value)
    },
    async updateTournament() {
      try {
        const api = new AdminApi.JTATournamentApi(this.getApiConfig());
        var result = await api.jtaTournamentsTournamentIdPostAsync(
            this.tournamentId,
            this.jtaTournament
        );
        Common.showToast("大会が変更されました。")
        this.backToTournament()
        this.getData()
      } catch (e) {
        this.loading = false;
        this.errors = Common.getErrorList(e.response, this.errors)
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async deleteTournament() {
      try {
        const api = new AdminApi.JTATournamentApi(this.getApiConfig());
        var result = await api.jtaTournamentsTournamentIdDeleteAsync(
            this.tournamentId,
        );

        Common.showToast("大会が削除されました。")
        if (result) {
          this.loading = false
        }

      } catch (e) {
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async getTournament() {
      try {
        const api = new AdminApi.JTATournamentApi(this.getApiConfig());

        this.jtaTournament = await api.jtaTournamentsTournamentIdGetAsync(
            this.tournamentId,
            this.$route.query.status,
            this.$route.query.startAt,
            this.$route.query.endAt,
            this.$route.query.keyword,
            this.$route.query.sort,
            this.$route.query.order
        );
        this.nextTournamentId = this.jtaTournament.nextTournamentId
        this.nextTournamentName = this.jtaTournament.nextTournamentName
        this.prevTournamentName = this.jtaTournament.prevTournamentName
        this.prevTournamentId = this.jtaTournament.prevTournamentId
      } catch (e) {
        this.jtaTournament = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async getPrefecture() {
      try {
        const api = new AdminApi.SystemApi(this.getApiConfig());
        var result = await api.systemPrefecturesGetAsync();
        this.prefectures = result.items;
      } catch (e) {
        this.prefectures = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    updateStartAt(date) {
      this.jtaTournament.startAt = date
    },
    updateEndAt(date) {
      this.jtaTournament.endAt = date
    },
    createSearchQuery() {
      return Common.appendSearchQuery(this.$route.query)
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-file {
  position: relative;
  overflow: hidden;
  border: 1px solid #f2ecf0;
  height: 32px;
  line-height: 30px;
  border-radius: 0px;
}

.fa-upload {
  color: #f2ecf0;
}

.button-container {
  padding: 0px 186px;
}

.date-range {
  width: 21%;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 999px;
  text-align: right;
  opacity: 0;
  outline: none;
  background: #fff;
  cursor: pointer;
  display: block;
}

.form-control {
  &:not(select) {
    //background: #F2ECF0;
    border: none;
  }

  &.datepicker {
    &::-webkit-calendar-picker-indicator {
      background: url("../assets/images/calendar-event.svg") no-repeat;
    }
  }
}


::placeholder {
  color: #333333;
}

.custom-button {
  background: #c95d6c;
  color: #ffffff;
  border-radius: 4px;
  width: 160px;
}

.custom-button-outline.btn {
  background: #ffffff;
  color: #c95d6c;
  border: 1px solid #c95d6c;
  border-radius: 4px;
  width: 160px;
}

.card-container {
  padding: 7rem !important;
  padding-top: 2rem !important;
}

.filter-select-long {
  background-image: url("../assets/images/down.png");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  height: 44px !important;
}

.ok {
  width: 414px;
  height: 44px;
  color: white;
  background-color: #c95d6c;
}

.ok:active {
  color: white;
  background-color: #c95d6c;
}

.cancel {
  width: 414px;
  height: 44px;
  color: #333333;
  border: none;
  background-color: #ffffff;
}

.cancel:active {
  color: #c95d6c;
  background-color: #ffffff;
}

.delete {
  width: 200px;
  height: 44px;
  color: white;
  background-color: #333333;
}

.delete:active {
  color: white;
  background-color: #333333;
}

.ok-min {
  width: 200px;
  height: 44px;
  color: white;
  background-color: #c95d6c;
}

.ok-min:active {
  color: white;
  background-color: #c95d6c;
}

.cancel-min {
  width: 200px;
  height: 44px;
  color: #333333;
  border: none;
  background-color: #ffffff;
}

.cancel-min:active {
  color: #333333;
  background-color: #ffffff;
}

a {
  color: #333333;
}

.filter-select {
  //background-image: url('./src/assets/images/down.png');
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  height: 32px !important;
}

.filter-search-form {
  border: 1px solid #dee2e6 !important;
  height: 32px !important;
}

.dob-section {
  .parent-block {
    .child-block {
      position: absolute;
      top: 0px;
      background: #f8f8f8;
      justify-content: center;
      align-items: center;
      display: flex;
      border: 1px solid #f0e7ed;
      border-radius: 0px 4px 4px 0px;
      height: 100%;
      max-height: 48px;
      width: 2.25rem;
      z-index: 9999;
      right: 0;
      color: #888888;
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }
  }

  .form-control {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }
}

.custom-select-year {
  width: 28%;
  margin-right: 23px;

  &:first-child {
    width: 40%;
  }

  &:last-child {
    width: 28%;
    margin-right: 0px !important;
  }
}

.content {
  &.tournaments {
    .text-888888 {
      color: #888888;
    }

    .custom-button {
      height: 32px;
      padding-top: 3px;

      img {
        margin-top: 2px;
      }
    }

    .custom-button-outline.btn {
      height: 32px;
      padding-top: 3px;

      img {
        margin-top: 2px;
      }
    }
  }

  &.tournamentdetail {
    .btn-width {
      width: 160px;
    }

    @media only screen and (max-width: 991px) {
      .btn-width {
        width: 140px;
      }
    }
  }
}

</style>
